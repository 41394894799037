import { aaABI } from "./abis/AA.js"
import * as constant from "./components/constants"

const AAContractAddress = constant.contractAddress
let web3
let account
let AA_contract
let price
let Whitelisted
let SaleStatus
let PublicMint
let nonce
let freeMints

export const InitContract = async (_web3) => {
    web3 = _web3
    const network = await networkId(_web3)
    constant.testlog(`ContractHelper - INIT CONTRACT for networkId: ${network}`)

    const accounts = await web3.eth.getAccounts()
    account = accounts[0]
    constant.testlog(`ContractHelper - account: ${account}`)

    AA_contract = await new web3.eth.Contract(aaABI, AAContractAddress)
    constant.testlog(`ContractHelper - ERC721 Contract Address: ${AAContractAddress}`)

    price = await getNFTPrice();
    constant.testlog(`ContractHelper - NFT price: ${price}`)

    Whitelisted = await isWhitelisted();
    constant.testlog(`ContractHelper - is Whitelisted: ${Whitelisted}`)

    freeMints = await canFreeMint();
    constant.testlog(`ContractHelper - available FreeMints: ${freeMints}`)

    SaleStatus = await isSaleActive();
    constant.testlog(`ContractHelper - is Sale Active?: ${SaleStatus}`)

    PublicMint = await isPublicMint();
    constant.testlog(`ContractHelper - is PublicMint Active?: ${PublicMint}`)

    nonce = await Nonce();
    constant.testlog(`Nonce: ${nonce}`);

    if (freeMints>0) return "Freemint"
    else { return Whitelisted}
}

export const mintAA = async (URI) => {
//TODO handle error
    const mintFee = price //get it from contract
    //const amount = web3.utils.toWei(mintFee, 'ether');
    const value = web3.utils.toHex(mintFee);
     constant.testlog("minting");

    const tx = {
        'from': account.toString(),
        'to': AAContractAddress,
        'value': value,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
    };
    return await AA_contract.methods.mint(URI).send(tx)
}

export const FreeMintAA = async (URI) => {
    //TODO handle errors
    const mintFee = 0 //zero because is free
    const value = web3.utils.toHex(mintFee);
    constant.testlog("free minting");
    
        const tx = {
            'from': account.toString(),
            'to': AAContractAddress,
            'value': value,
            maxPriorityFeePerGas: null,
            maxFeePerGas: null,
        };
        return await AA_contract.methods.freemint(URI).send(tx)
    }

export const validateAddress = (address) => {
    return web3.utils.isAddress(address)
}

export const getNFTPrice = async() => {
    const NFT_price = await AA_contract.methods.price().call()
    return NFT_price
}

export const isWhitelisted = async() => {
    const isWhitelisted = await AA_contract.methods.whitelistWallets(account).call()    
    return isWhitelisted
}

export const canFreeMint = async() => {
    const canFreeMint = await AA_contract.methods.freeMintWallets(account).call()    
    return canFreeMint
}

export const networkId =  async (_web3) => {
    web3 = _web3
    const NetId = await web3.eth.net.getId()
    return NetId
}

export const isSaleActive = async() => {
    const SaleStatus = await AA_contract.methods.saleActive().call()
    return SaleStatus
}

export const isPublicMint = async() => {
    const PresaleDown = await AA_contract.methods.PublicMintActive().call()
    return PresaleDown
}

export const Nonce = async() => {
    const nonce = await AA_contract.methods.nonce().call()
    return nonce
}

export { nonce, freeMints, SaleStatus, PublicMint, Whitelisted }
