import React, { useState, useEffect } from "react";
import './popup.css';

const Popup = ({ message, duration, showPopup }) => {
  const [isVisible, setIsVisible] = useState(showPopup);

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setIsVisible(false);
      }, duration);
    }
  }, [isVisible, duration]);

  return (
    isVisible && (
      <div className="checker_popup">
        {message}
      </div>
    )
  );
};

export default Popup;
