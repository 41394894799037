import { useState, useEffect } from 'react';
import { File } from 'nft.storage'
import { Buffer, constants } from 'buffer';
import { ethers } from 'ethers';
import mergeImages from 'merge-images';
import './app.css';
import { getAIImage, base64ToFile, uploadOnIPFS } from './components/utils';
import * as constant from './components/constants'
import FooterDesktop from './components/footer';
import FAQ from './components/FAQ';

// Components
import Spinner from 'react-bootstrap/Spinner';
import Navigation from './components/Navigation';
import Countdown from 'react-countdown';

import frame from './card.png'
import MintPopup from './components/MintPopup';
import * as web3function from './ContractHelper'

import ReactGA from "react-ga4";
ReactGA.initialize("G-VKPWKCF6DY");

function App() {

  const [inputValue, setInputValue] = useState('');

  const [provider, setProvider] = useState(null)
  const [account, setAccount] = useState(null)

  const [loading, setLoading] = useState(false);

  const [description, setDescription] = useState(`${constant.AItheme}`)
  const [image, setImage] = useState(null)
  const [url, setURL] = useState(null)
  const [nonceId, setNonce] = useState(null)
  const [freeMint, setFreeMints] = useState(null)

  const [message, setMessage] = useState("")
  const [isWaiting, setIsWaiting] = useState(false)

  const [popupText, setPopupText] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  const [displayText, setDisplayText] = useState(constant.AItheme + 'pink hair, green eyes, blue space suit, mountain on the background, drinking a cocktail');
  const [sentences, setSentences] = useState(constant.sentences);
  const [hasModified, setmodified] = useState(false);




  const loadBlockchainData = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    setProvider(provider)

    const network = await provider.getNetwork()

  }

  const handleClick = async (e) => {
    e.preventDefault()

    ReactGA.event({
      category: "Mint Click",
      action: "Click"
    });

    let nonce;
    let freemint = false;

    //IF constant == PRODUCTION preliminary test are done
    if (constant.isTesting == "PRODUCTION") {
      //CHECK IF THERE IS INPUT
      if (description === constant.AItheme) {
        setPopupText("INPUT DATA")
        setIsVisible(true);
        return
      }
      //CHECK IF IS CONNECTED
      if (!account) {
        setPopupText("NOT CONNECTED")
        setIsVisible(true);
        return
      }
      //CHECK NONCE
        if (web3function.nonce >= constant.MaxNrnft) {
          setPopupText("COLLECTION SOLD OUT")
          setIsVisible(true);
          return
        } else {
          //CHECK IF CAN FREE MINT
            if (web3function.freeMints> 0 && web3function.nonce < constant.MaxNrnft) {
              freemint = true;
              setPopupText("FREE MINTING");
              setIsVisible(true);
            } else {
              //CHECK IF SALE IS ACTIVE
                if (web3function.SaleStatus == false && freemint == false) {
                  setPopupText("SALE NOT ACTIVE");
                  setIsVisible(true);
                  return;
                }
              //CHECK IF WHITELISTED AND PUBLIC MINT STATUS
                  if (!web3function.Whitelisted && !web3function.PublicMint) {
                    setPopupText("NOT WHITELISTED");
                    setIsVisible(true);
                    return;
                  }
                  //CHECK IF COLLECTION IS SOLD OUT
                  if (web3function.nonce >= constant.MaxNrnft) {
                    setPopupText("SOLD OUT");
                    setIsVisible(true);
                    return;
                  }
            }
        }
    }

    setIsWaiting(true)
    setLoading(true);

    //Call AI API to generate a image based on description
    const imageData = await createImage()

    if (constant.isTesting == "TRAIL" && imageData != null) {
      setIsWaiting(false)
      setMessage("")
    }

    // Upload image to IPFS (NFT.Storage)
    if (imageData != null && constant.isTesting == "PRODUCTION") {
      constant.testlog("nonce ", nonceId)
      let NFTname = "AI Girlz - Spacecadet #" + await web3function.nonce.toString();
      constant.testlog(NFTname);
      const url = await uploadImage(imageData, NFTname);
      constant.testlog(url);
      setIsWaiting(false)
      setMessage("")
      setLoading(false);
      //NFT MINT
      
      freemint ? await mintImageFree(url) : await mintImage(url)
    }
    setLoading(false);
  }

  function isImageMostlyBlack(imgUrl) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = imgUrl;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
        let blackPixels = 0;

        for (let i = 0; i < data.length; i += 4) {
          const r = data[i];
          const g = data[i + 1];
          const b = data[i + 2];
          const brightness = (r + g + b) / 3;
          if (brightness <= 20) { // check if pixel is black (brightness <= 20)
            blackPixels++;
          }
        }

        const blackPixelRatio = blackPixels / (canvas.width * canvas.height);
        if (blackPixelRatio > 0.9) { // if more than 90% of pixels are black, consider image mostly black
          resolve(true);
        } else {
          resolve(false);
        }
      };

      img.onerror = (err) => {
        reject(err);
      };
    });
  }


  //image creation
  const createImage = async () => {
    setMessage("Generating Image...");
    const response = await getAIImage("Sexy, " + description + ", 4k illustration, universe");


    const data = await response.arrayBuffer();
    if (data.byteLength > 1000) {


      constant.testlog("The ArrayBuffer contains data");
      const type = response.headers.get('content-type');
      const base64data = Buffer.from(data).toString('base64');
      const img = `data:${type};base64,` + base64data;
      const IsBlack = await isImageMostlyBlack(img);

      if (IsBlack) {
        constant.testlog("The Image is Black");
        setMessage("Error In generating the Image, change prompt");
        return null;
      }


      //merge AI image with frame
      let b64 = await mergeImages([
        { src: img, x: constant.dxframe, y: constant.dyframe },
        { src: frame, x: 0, y: 0 }]);

      setImage(b64);
      let imageToLoad = new File([b64], "image.png", { type: "image/png" });
      //return Imagedata from merged image
      let dataImage = base64ToFile(b64)
      return dataImage;

    } else {
      constant.testlog("The ArrayBuffer is empty");
      setMessage("Error In generating the Image, wait few minutes");
      return null;
    }
  }

  // Function to randomly display a sentence after 5 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * sentences.length);
      setDisplayText(sentences[randomIndex]);
    }, 5000);
    return () => clearInterval(intervalId);
  }, [sentences]);


  //upload image on ipfs
  const uploadImage = async (imageData, NFTname) => {
    setMessage("Uploading Image...")
    const ipnft = await uploadOnIPFS(imageData, NFTname, description)
    // Save the URL
    const url = `https://ipfs.io/ipfs/${ipnft}/metadata.json`
    setURL(url)

    return url
  }

  const mintImage = async (tokenURI) => {
    //setIsWaiting(true)
    //setLoading(true);
    setMessage("Waiting for Mint...")
    await web3function.mintAA(tokenURI);
  }

  const mintImageFree = async (tokenURI) => {
    //setIsWaiting(true)
    //setLoading(true);
    setMessage("Waiting for Mint for Free...")
    await web3function.FreeMintAA(tokenURI);
  }

  useEffect(() => {
    loadBlockchainData()
  }, [])

  const handleInputChange = (event) => {

    const value = event.target.value;
    setmodified(true);
    if (value.startsWith(`${constant.AItheme}`)) { setDescription(value);
    } else {
      if (value.length==1) {
        setDescription(`${constant.AItheme}${value}`);
      } else {
      if (value.length<constant.AItheme.length) {setDescription(`${constant.AItheme}`);}

      else {setDescription(`${constant.AItheme}${value}`);}
    }
    }
  };

  return (
    <div className='app'>
      <Navigation account={account} setAccount={setAccount} />


      <div className='form'>

        <form>
          <div className='Countdown-box'>
            <div className='Countdown-toMint'>TO MINT </div>
            <div className='Countdown-copy'>

              <Countdown date={new Date(constant.mintTime)} /> </div>
            <br /><div className='Countdown-text-copy'>DD  -  HH  -  MM  -  SEC</div><br />
          </div>

          <div className='description-copy'>
            {constant.Inputcopy}
          </div>
          {/* <input type="text" placeholder="Create a name... MAX 20 charaters"
           onChange={(e) => { setName(e.target.value) }} /> */}
          {(!hasModified) ? (<input type="text" placeholder={displayText} onChange={handleInputChange} maxLength={constant.maxDescriptionLength} />
          ) : (<input type="text" value={description} onChange={handleInputChange} maxLength={constant.maxDescriptionLength} />
          )}
          <button className={loading ? "submit_button_blocked" : "submit_button"} type="submit" disabled={loading} onClick={handleClick}>
            {loading ? "Processing..." : constant.Buttontext}
          </button>
          {(isVisible) ? (
            <MintPopup message={popupText} duration={constant.popupduration} showPopup={true} setIsVisible={setIsVisible} />
          ) : (null
          )}
        </form>

        <div className="image">
          {!isWaiting && image ? (
            <img src={image} alt="AI generated image" style={{
              height: '457px',
              width: '328px'
            }} />
          ) : isWaiting ? (
            <div className="image__placeholder">
              <Spinner animation="border" />
              <p>{message}</p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <FAQ />
      <FooterDesktop />

    </div>
  );
}

export default App;
