import React from "react"
import "./FooterDesktop.css"
import * as constant from "./constants"
import twitterIcon from "./twitter-icon.svg"
import OpenseaIcon from "./opensea-icon.svg"
import Etherscan from "./etherscan-icon.svg"
import DiscordIcon from "./discord-icon.svg"

export default function FooterDesktop() {

  async function hyperlink(url) {
    window.open(url, '_blank').focus();
  }

  return (
  
    <div className="footer-desktop">
      <img
        src={twitterIcon}
        className="set-icone-social-orizz"
        onClick={e => { e.preventDefault(); hyperlink(constant.twitterlink) }}
      />
      <img
        src={OpenseaIcon}
        className="set-icone-social-orizz"
        onClick={e => { e.preventDefault(); hyperlink(constant.opensealink) }}
      />
      <img
        src={Etherscan}
        className="set-icone-social-orizz"
        onClick={e => { e.preventDefault(); hyperlink(constant.etherscanlink) }}
      />
      <img
        src={DiscordIcon}
        className="set-icone-social-orizz"
        onClick={e => { e.preventDefault(); hyperlink(constant.discordlink) }}
      />
  </div>
     
  )
}