import * as constant from "./constants";
import { NFTStorage, File } from 'nft.storage'
import { constants } from "buffer";

export const getAIImage = async (description) => {
    constant.testlog(description);
    const URL = constant.model_api_base + constant.hentaiModel;
    // Send the request
    const response = await fetch(URL, {
        headers: { Authorization: `Bearer ${constant.AI_API_KEY}` },
        method: "POST",
        body: JSON.stringify(description),
    });
    return response
}

export async function base64ToFile(dataURL) {
    return (fetch(dataURL)
        .then(function (result) {
            return result.arrayBuffer();
        }));
}

export const uploadOnIPFS = async (imageData,name,description) => {
     // Create instance to NFT.Storage
     const nftstorage = new NFTStorage({ token: constant.STORAGE_API_KEY })

     let imageToLoad = new File([imageData], "image.png", { type: "image/png" });
 
     // Send request to store image
     const { ipnft } = await nftstorage.store({
       image: imageToLoad,
       name: name,
       description: description,
     })

     return ipnft;

}