
export const model_api_base = "https://api-inference.huggingface.co/models/"
//export const hentaiModel = "Linaqruf/anything-v3.0"
export const hentaiModel = "eimiss/EimisAnimeDiffusion_1.0v"

export const diffusion14Model = "CompVis/stable-diffusion-v1-4"

export const AItheme = "Spacecadet girl, ";
//export const AItheme = "Pokemon ";

export const dxframe = 70;  //put to 38 when using anything-v3.0
export const dyframe = 70;  //put to 38 when using anything-v3.0

export const AI_API_KEY = "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"
export const STORAGE_API_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweGM3MjREMWNkYThGMTg1M2IzMzk3Y0U2MkE1YjFBQWMwRjk4YjVFNzgiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY3NTUzNjI3MDMzMywibmFtZSI6IkFydGlmaWNhbEFlc3RldGljcyJ9.IuhdrZ8LYblqZh5FWkxrvYNc3pUupwAits0dPHK9bcg"

//COPY
export const Inputcopy = 
"Type one or two words here and let the AI magic happen"

export const sentences =
[AItheme+"blue hairs, green eyes, cyborg beauty", 
AItheme+"green hairs, circuit-veined AI goddess", 
AItheme+"nanobot-teared machine beauty", 
AItheme+"digital diva, red eyes, pixelated hair"]
//POPUP
export const popupduration = 4000;

//COUNTDOWN
export const mintTime = "5/11/2023";

//SOCIAL
export const twitterlink = "https://twitter.com/AIGirlz_nft";
export const etherscanlink = "https://etherscan.io/address/0xe17b1de55a9855036873682442444ff308d7c4c5";
export const opensealink = "";
export const discordlink = "https://discord.gg/dU56cxVhmp";


//BLOCKCHAIN
export const networknr = 1;
export const contractAddress = "0xE17B1de55A9855036873682442444Ff308D7C4C5"
export const maxDescriptionLength = 80;
export const MaxNrnft =333;

//TEST
export const showLog = 0;
export const isTesting = "PRODUCTION"; 
//PUT TRIAL for testing purpose, only image mint
//PUT PRODUCTION when mint will be ready

//LANDING PAGE TEXTS
export const Buttontext = "Create & Mint"

export const testlog = (log) => {
    if (showLog) {
        console.log(log)
    }

}