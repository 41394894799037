import React, { useState } from 'react';
import './FAQ.css';

const FAQ = () => {
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [expanded4, setExpanded4] = useState(false);
  const [expanded5, setExpanded5] = useState(false);

  const toggleExpand1 = () => {
    setExpanded1(!expanded1);
  }
  const toggleExpand2 = () => {
    setExpanded2(!expanded2);
  }
  const toggleExpand3 = () => {
    setExpanded3(!expanded3);
  }
  const toggleExpand4 = () => {
    setExpanded4(!expanded4);
  }
  const toggleExpand5 = () => {
    setExpanded5(!expanded5);
  }

  return (
    <div className="faq">
      <div className="faq-item">
        <div className="faq-question" onClick={toggleExpand1}>
          <h3>What is AI Girlz?</h3>
          {expanded1 ? <span>-</span> : <span>+</span>}
        </div>
        {expanded1 &&
          <div className="faq-answer">
            <p>AI Girlz is an artificial intelligence generated nft project. Unlike any nft project, we’ll be accompanied by female characters on our journey through the multiverse.</p>
          </div>
        }
      </div>
      <div className="faq-item">
        <div className="faq-question" onClick={toggleExpand2}>
          <h3>What is the purpose of AI Girlz?</h3>
          {expanded2 ? <span>-</span> : <span>+</span>}
        </div>
        {expanded2 &&
          <div className="faq-answer">
            <p>In our opinion AI is a crazy tool which can simplify our lives. AI Girlz aims to give free AI tools to holders.</p>
          </div>
        }
      </div>
      <div className="faq-item">
        <div className="faq-question" onClick={toggleExpand3}>
          <h3>How does this work?</h3>
          {expanded3 ? <span>-</span> : <span>+</span>}
        </div>
        {expanded3 &&
          <div className="faq-answer">
            <p>When you submit your prompt, it is put in a queue to be processed by our AI anime girl model.</p>
          </div>
        }
      </div>
      <div className="faq-item">
        <div className="faq-question" onClick={toggleExpand4}>
          <h3>What are some tips for writing good prompts?</h3>
          {expanded4 ? <span>-</span> : <span>+</span>}
        </div>
        {expanded4 &&
          <div className="faq-answer">
            <p>While not strictly necessary, forming a complete sentence yields more consistently high quality results. If you want to include related keywords in your prompt, do so at the end separated by commas.</p>
          </div>
        }
      </div>
      <div className="faq-item">
        <div className="faq-question" onClick={toggleExpand5}>
          <h3>My image didn’t have any semblance to my prompt. What gives?</h3>
          {expanded5 ? <span>-</span> : <span>+</span>}
        </div>
        {expanded5 &&
          <div className="faq-answer">
            <p>If you load too many details into the prompt, the AI won't be able to include everything. Also, make sure you spell all things correctly, otherwise the AI won't know what you're referring to! In some cases, the AI simply just doesn't have enough training with some terms, so they might be harder for it to generate.</p>
          </div>
        }
      </div>
    </div>
  );
}

export default FAQ;
