import React, { useState, useEffect } from "react";
import './popup.css';

const MintPopup = ({ message, duration, showPopup, setIsVisible  }) => {


  useEffect(() => {
    if (showPopup) {
      setTimeout(() => {
        setIsVisible(false);
      }, duration);
    }
  }, [showPopup, duration]);

  return (
    showPopup && (
      <div className="mint_popup">
        {message}
      </div>
    )
  );
};

export default MintPopup;
