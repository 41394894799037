import { ethers } from 'ethers';
import * as web3function from '../ContractHelper'
import Web3 from 'web3';
import React, { useState, useEffect } from "react";
import Popup from "./popup";
import * as constant from "./constants"

import logo from "./logo.png"


const Navigation = ({ account, setAccount }) => {

    const [isVisible, setIsVisible] = useState(false);
    const [Network, setNetwork] = useState(constant.networknr);
    const [Freemints, setFreeMints] = useState(false);
    const [loaded, setLoaded] = useState(false);

    let isWhitelisted
    let networknr
    let web3
    const connectHandler = async () => {
        web3 = new Web3(Web3.givenProvider || 'http://localhost:7545');
        let accounts = await web3.eth.requestAccounts();

        const updateAccount = async () => {
            try {
                accounts = await web3.eth.requestAccounts();
                setAccount(accounts[0]);
            } catch {
                setAccount(null);
            }
        };

        updateAccount()
        subscribeToNetworkEvents(updateAccount)
        const NetId = await web3.eth.net.getId();

        if (NetId == constant.networknr) {
            isWhitelisted = await web3function.InitContract(web3);
            if (isWhitelisted == "Freemint") { setFreeMints(true) }

            setIsVisible(isWhitelisted);
            networknr = await web3function.networkId(web3);
        } else {
            setNetwork(networknr);
        }

        setLoaded(true);
    }

    function subscribeToNetworkEvents(callback) {
        constant.testlog(`subscribing to Network Events`)
        if (window.web3) {
            window.ethereum.on('chainChanged', async (networkId) => {
                constant.testlog("chainChanged");
                await web3function.InitContract(web3);
                callback();
            });

            window.ethereum.on('accountsChanged', async (accounts) => {
                constant.testlog("accountsChanged");
                await web3function.InitContract(web3);
                callback();
            });
        }
    }

    return (
        <nav>
            <div className='nav__brand'>
                <img src={logo} alt="AI generated image" style={{
                    width: '90%'
                }} />
            </div>

            {account ? (
                <div className='connectArea'>
                    <button
                        type="button"
                        className='nav__connect'
                    >
                        {account.slice(0, 6) + '...' + account.slice(38, 42)}

                    </button>
                    {(!isVisible && Network == constant.networknr && loaded && !web3function.PublicMint) ? (
                        <Popup message="NOT WHITELISTED!" duration={constant.popupduration} showPopup={true} />
                    ) : (null
                    )}
                    {(isVisible && Network == constant.networknr && loaded) ? (
                        <Popup message="WHITELISTED!" duration={constant.popupduration} showPopup={true} />
                    ) : (null
                    )}
                    {(Network != constant.networknr && loaded) ? (
                        <Popup message="WRONG NETWORK!" duration={constant.popupduration} showPopup={true} />
                    ) : (null
                    )}
                    {(Freemints && Network == constant.networknr && loaded) ? (
                        <Popup message="YOU CAN FREE MINT!" duration={constant.popupduration} showPopup={true} />
                    ) : (null
                    )}
                </div>
            ) : (
                <button
                    type="button"
                    className='nav__connect'
                    onClick={connectHandler}
                >
                    Connect
                </button>
            )}
        </nav>
    );

}

export default Navigation;